import PropTypes from "prop-types";
const Telegram = ({ className = "" }) => {
  return (
    <div className={`relative isolate ${className}`}>
      <div className="absolute inset-1 -z-10 bg-current rounded-full" />

      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="67"
        height="67"
        viewBox="0 0 67 67"
        fill="none"
        className="w-full h-full"
      >
        <path
          d="M31.7975 67C29.6756 66.8613 27.5765 66.5819 25.525 66.0345C15.0011 63.2246 7.44616 56.8745 2.86023 46.9844C1.39628 43.8307 0.543377 40.4928 0.191627 37.0309C0.124627 36.3683 0.06432 35.7043 0 35.041V31.959C0.0262405 31.8301 0.0459276 31.7 0.0589685 31.5691C0.146252 29.5295 0.449895 27.5051 0.964802 25.5297C3.73681 15.1188 9.99462 7.60361 19.7382 2.98418C23.1894 1.34804 26.8543 0.43349 30.6652 0.12395C31.0974 0.0891104 31.5282 0.04154 31.959 0H35.041C35.171 0.0263009 35.3023 0.0457574 35.4343 0.0582897C37.4785 0.15745 39.5012 0.4355 41.4737 0.96547C52.3277 3.8822 59.9974 10.4982 64.4828 20.8136C65.8463 23.9498 66.6027 27.2563 66.8767 30.6686C66.9116 31.0994 66.9591 31.5289 67 31.959V35.041C66.9806 35.171 66.9484 35.2996 66.9417 35.4303C66.8431 37.479 66.5373 39.5126 66.0292 41.4998C63.9691 49.5251 59.5748 55.9588 52.8463 60.8012C48.8484 63.6822 44.3996 65.5582 39.5501 66.4432C38.1116 66.7059 36.6483 66.8345 35.3961 66.998L31.7975 67ZM21.8889 36.716C22.6527 36.7294 23.1358 36.5559 23.588 36.2651C28.6175 33.0357 33.6476 29.8074 38.6784 26.5802C38.8948 26.4416 39.1059 26.2941 39.3317 26.1735C39.5327 26.0663 39.7256 26.1186 39.8677 26.2982C40.0097 26.4777 40.0191 26.666 39.8717 26.8482C39.8064 26.9244 39.7366 26.9967 39.6627 27.0646C36.289 30.3222 32.9167 33.58 29.5457 36.8379C28.2398 38.1016 28.4067 39.9233 29.9081 40.929C33.9221 43.6143 37.9562 46.2702 41.9413 48.9971C43.1339 49.8132 45.1955 49.3542 45.4796 47.434C45.6947 45.9781 45.8903 44.5202 46.092 43.0629C46.4359 40.5785 46.7792 38.0942 47.1218 35.6098C47.4644 33.1255 47.8141 30.642 48.171 28.1594C48.5254 25.6315 48.8859 23.1043 49.2216 20.5744C49.2886 20.0497 49.3696 19.4896 49.2604 18.9858C48.9663 17.629 47.5338 16.9369 46.2206 17.4696C44.3011 18.2481 42.3909 19.0474 40.4774 19.8394L13.6546 30.9426C12.5478 31.4009 12.0205 32.3128 12.1913 33.4658C12.3173 34.3127 12.9538 35.0021 13.7719 35.1603C15.9038 35.5716 18.0364 35.9803 20.1697 36.3864L21.8889 36.716Z"
          fill="#580704"
        />
      </svg>
    </div>
  );
};

Telegram.propTypes = {
  className: PropTypes.string,
};

export default Telegram;
